import React, { useRef } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactImage } from '../assets'

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_CONTACT;
const USER_ID = process.env.REACT_APP_USERID;

const Contact = () => {

  const form = useRef()
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
      .then((result) => {
        console.log(result);
        toast.success("Message Sent Successfully");
      }, (error) => {
        console.log(error);
        toast.error("Something went wrong. Try again");
      });
      
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className='flex justify-between items-center w-full md:px-[100px] px-4 gap-16'>
      <div className="md:w-1/2 w-full">
        <h2 className="md:text-3xl text-xl font-bold mb-2">
          CONTACT US
        </h2>
        <p className="mb-6">
          Our customer support staff are ever ready to help you out with any questions, issues or concerns you have at any point in time.
        </p>
        <form ref={form} className="w-full" onSubmit={handleSubmit(onSubmit, handleError)}>
          <input type="text" className="bg-transparent border border-primary placeholder:text-xs placeholder:text-black h-12 pl-6 w-full mb-4" placeholder='Full Name' name='fullName' {...register("fullName")} required />
          <input type="text" className="bg-transparent border border-primary placeholder:text-xs placeholder:text-black h-12 pl-6 w-full mb-4" placeholder='Email Address' name='email' {...register("email")} required />
          <input type="text" className="bg-transparent border border-primary placeholder:text-xs placeholder:text-black h-12 pl-6 w-full mb-4" placeholder='Phone Number' name='phone' {...register("phone")} required />
          <textarea type="text" className="bg-transparent border border-primary placeholder:text-xs placeholder:text-black h-28 pl-6 pt-4 w-full mb-6 resize-none" placeholder='Your Message' name='message' {...register("message")} required />
          <button 
            type='submit'
            className="py-3 w-full text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          >
            SUBMIT
          </button>
        </form>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <p className="text-sm font-medium mt-4">
          Customer Support Call Center: (+44) 784-2943-795
        </p>
      </div>
      <img src={contactImage} alt="" className="md:block hidden w-1/2" />
    </div>
  )
}

export default Contact