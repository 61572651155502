import React from "react";
import { useNavigate } from "react-router-dom";

const Work = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center py-[80px] px-4 border-y border-primary text-center">
      <h2 className="md:text-3xl text-xl font-bold md:mb-4 mb-2">
        Want to work with us? Reach Out.
      </h2>
      <p className="mb-6 md:w-[60%] w-full">
        Are you interested in collaborating with us on a Project or you're an
        expert? We are open to work on any kind of small scale or large scale
        projects.. Why not reach out to us via phone (+44) 784-2943-795 or email at
        enquiries@worksitesrecruitment.co.uk. We look forward to hearing from
        you!
      </p>
      <div className="flex items-center gap-4">
        <a
          href="https://forms.gle/aXB7CheQtegiED3k8"
          target="blank"
          className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
        >
          Join Us
        </a>
        <button
          className="md:py-3 py-2 md:px-12 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Work;
