import React from "react";
import { aboutImage } from "../assets";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#F9F9F9] md:px-[100px] px-4 md:py-16 py-10 border-b-[10px] border-primary flex items-center gap-12">
      <img src={aboutImage} alt="" className="md:block hidden w-[35%]" />
      <div className="">
        <h2 className="md:text-3xl text-xl font-bold mb-4">About Us</h2>
        <p className="mb-6">
          At Worksites Recruitment, we redefine construction workforce
          recruitment with a commitment to excellence and a passion for building
          success. Leveraging on our database of professionals with years of
          experience and passion for value, we aim to collaborate and partner
          with top-tier construction businesses to deliver projects that satisfy
          and meet the expectations of their customers.
          <br />
          <br />
          Our mission is clear: to bridge the gap between skilled construction
          workers and the companies that rely on their expertise. We understand
          the unique challenges of the construction industry, and our team of
          dedicated experts is driven to provide the well needed workforce
          solution.
        </p>
        <button
          className="md:py-3 py-2 md:px-12 px-6 text-white bg-primary hover:bg-black hover:outline-black font-semibold outline outline-1 outline-primary"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default About;
