import { projectIcon, ratingIcon, satisfactionIcon } from "../assets";

export const navLinks = [
  {
    id: 1,
    linkName: 'Home',
    path: '/'
  },
  {
    id: 2,
    linkName: 'Services',
    path: '/services'
  },
  {
    id: 3,
    linkName: 'About',
    path: '/about'
  },
  {
    id: 4,
    linkName: 'Contact',
    path: '/contact'
  },
];

export const statistics = [
  {
    id: 1,
    stat: '100%',
    statDescription: 'Client Oriented',
    statImage: projectIcon,
  },
  {
    id: 2,
    stat: 'Very',
    statDescription: 'Professional',
    statImage: satisfactionIcon,
  },
  {
    id: 3,
    stat: '100%',
    statDescription: 'Clients Satisfaction',
    statImage: ratingIcon,
  },
]

export const customers = [
  {
    id: 1,
    customerName: 'Mr. Rasheed, Co-founderDuckpin Arena',
    summary: "Worksites Recruitment has been a game-changer for our hiring process. Their ability to source top-tier talent with the right experience and cultural fit has significantly improved our team’s performance. The professionalism and efficiency they bring to recruitment make them a trusted partner for our workforce needs."
  },
  {
    id: 2,
    customerName: 'Mr Muhammed, Co-founder Reviewi',
    summary: "Finding skilled and reliable professionals in our industry has always been a challenge, but Worksites Recruitment made it seamless. Their deep understanding of workforce dynamics and commitment to quality ensured we got the best candidates for our operations. We highly recommend their services!"
  },
  {
    id: 3,
    customerName: 'Abi, CEO AD10 Consult',
    summary: "As a growing startup, we needed a recruitment partner that truly understood our vision and growth needs. Worksites Recruitment delivered beyond our expectations, providing us with passionate professionals who have become integral to our success. Their service is unmatched!"
  },
]