import React from 'react'
import { serviceIcon1, serviceIcon2, serviceIcon3, serviceIcon4, serviceIcon5, serviceIcon6, serviceIcon7, serviceIcon8, serviceIcon9 } from '../assets'
import { useNavigate } from 'react-router-dom'

const Services = () => {

  const navigate = useNavigate()

  return (
    <div className='md:px-0 px-4 md:pb-16 pb-10 flex flex-col items-center text-center md:gap-16 gap-10'>
      <div className="md:w-[62%] w-full">
        <h2 className="md:text-3xl text-xl font-bold mb-4">
          Our Services
        </h2>
        <p className="mb-6">
        At Worksites Recruitment we understand the critical role that skilled and reliable construction workers play in the success of your projects. As a pioneering recruitment agency specializing in the construction industry, we take pride in connecting businesses with top-tier talent to meet their unique project needs. 
          <br /><br />
          Our goal is not just to fill positions but to build lasting partnerships that contribute to the long-term growth and success of your construction projects. Contact us today to discuss how Worksites Recruitment can be your strategic ally in sourcing and securing the construction workforce your projects deserve.
        </p>
        <button 
          className="md:py-3 py-2 md:px-12 px-6 text-black bg-transparent hover:bg-[#e2e2e2] hover:outline-[#e2e2e2] font-semibold outline outline-1 outline-black"
          onClick={() => navigate('/contact')}
        >
          Contact Us
        </button>
      </div>

      {/* <div className="flex md:flex-row flex-col gap-10 w-full">
        <div className="flex flex-col gap-10 w-full">
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div 
              className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6"
            >
              <img src={serviceIcon1} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Carpenters
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon2} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Skilled Laborers
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon7} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Bricklayer
              </h3>
            </div>
          </a>
        </div>
        <div className="flex flex-col gap-10 w-full">
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon3} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Painters
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon4} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Electrician
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon8} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Plumber
              </h3>
            </div>
          </a>
        </div>
        <div className="flex flex-col gap-10 w-full">
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon5} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Welder
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon6} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                Tiler
              </h3>
            </div>
          </a>
          <a 
            href='https://forms.gle/aXB7CheQtegiED3k8' 
            target='blank'
            className='hover:shadow-xl'
          >
            <div className="flex flex-col items-center justify-center h-[200px] outline outline-2 outline-primary rounded-xl text-center p-6">
              <img src={serviceIcon9} alt="" className="w-16 h-16" />
              <h3 className="md:text-xl text-lg font-bold mt-6 mb-2">
                & Others
              </h3>
            </div>
          </a>
        </div>    
      </div> */}
    </div>
  )
}

export default Services